<template>
  <div class="secure-config" v-loading="loading">
    <el-form size="medium" class="small-form"
             label-width="140px" ref="secureForm"
             :model="config" :rules="rules"
    >
      <el-form-item label="密码有效期：" prop="valid_period">
        <el-input v-model.number="config.valid_period"></el-input>
        <div class="info"><i class="el-icon-info"></i>
          单位为月，密码超过有效期后将无法登录后台，需提前修改密码。
        </div>
      </el-form-item>
      <el-form-item label="过期提醒：" prop="valid_period_reminder">
        <el-input v-model.number="config.valid_period_reminder"></el-input>
        <div class="info"><i class="el-icon-info"></i>
          单位为天，如密码过期前10天，每次登录均有弹窗提醒密码即将过期，请及时修改密码。
        </div>
      </el-form-item>
      <!--      <el-form-item label="初始密码：">-->
      <!--        <el-input type="text" v-model="config.valid_period_reminder"></el-input>-->
      <!--        <el-button style="margin-left: 10px;" type="text">随机密码</el-button>-->
      <!--        <div class="info"><i class="el-icon-info"></i>-->
      <!-- 新建后台用户时的初始密码，长度8~32位，需包含字母、数字及特殊字符</div>-->
      <!--      </el-form-item>-->
      <el-form-item label="密码重试次数：" prop="login_retry_limit">
        <el-input v-model.number="config.login_retry_limit"></el-input>
        <div class="info"><i class="el-icon-info"></i>
          用户进行登录时，连续输入错误密码次数等于此次数时，需等待一段时间重试。
        </div>
      </el-form-item>
      <el-form-item label="登录错误等待时间：" prop="login_error_wait_time">
        <el-input v-model.number="config.login_error_wait_time"></el-input>
        <div class="info"><i class="el-icon-info"></i>
          单位为分，连续输入错误密码超过限制时，重新进行登录需等待的时间。
        </div>
      </el-form-item>
    </el-form>
    <fixed-action-bar>
      <el-button type="primary" size="medium" @click="saveConfig">保存</el-button>
    </fixed-action-bar>
  </div>
</template>

<script>
  import FixedActionBar from "../../../base/layout/FixedActionBar";

  export default {
    components: {FixedActionBar},
    data() {
      return {
        loading: true,
        config: {},
        rules: {
          valid_period: [
            {required: true, message: '请输入有效期'},
            {type: 'number', message: '请输入数字'},
          ],
          valid_period_reminder: [
            {required: true, message: '请输入天数'},
            {type: 'number', message: '请输入数字'}
          ],
          login_retry_limit: [
            {required: true, message: '请输入次数'},
            {type: 'number', message: '请输入数字'}
          ],
          login_error_wait_time: [
            {required: true, message: '请输入分钟'},
            {type: 'number', message: '请输入数字'}
          ]
        }
      }
    },
    created() {
      this.$request('/admin/admin/Settings/getAdminSecureConfig').then(res => {
        this.config = res.data
        this.loading = false;
      }).catch(err => {
        this.loading = false
      })
    },
    methods: {
      saveConfig() {
        this.$refs['secureForm'].validate((valid) => {
          if (valid) {
            this.loading = true
            this.$request('/admin/admin/Settings/setAdminSecureConfig', {
              config: this.config
            }).then(res => {
              this.$message.success(res.msg)
              this.loading = false
            }).catch(err => {
              this.loading = false
            })
          } else {
            return false;
          }
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .secure-config {

  }
</style>
